<template>
  <div>
    <div class="interval-16">
      <el-card shadow="never" :body-style="cardBodyStyle">
        <div slot="header">产品基础信息</div>

        <el-form
          label-position="right"
          label-width="280px"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :model="ruleForm"
        >
          <el-form-item label="logo（限一张）：" prop="logo">
            <el-upload
              action="#"
              ref="logo"
              :file-list="logoList"
              list-type="picture-card"
              :on-remove="handleLogoRemove"
              :http-request="customizeLogoUpload"
              :on-exceed="handleExceed"
              :limit="1"
              accept="image/jpg,image/png,image/jpeg"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="页面主视频：" prop="video">
            <el-upload
              action="#"
              ref="video"
              :file-list="videoList"
              :on-remove="handleVideoRemove"
              :http-request="customizeVideoUpload"
              :on-exceed="handleExceed"
              :on-preview="handleVideoPreview"
              :limit="1"
              accept="video/mp4,video/webm,video/ogg"
            >
              <el-button> 上传视频 </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="产品名称：" prop="productName">
            <el-input
              v-model="ruleForm.productName"
              placeholder="请输入产品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品slogan：" prop="productIntroduction">
            <el-input
              type="textarea"
              v-model="ruleForm.productIntroduction"
              placeholder="请输入产品slogan"
              :autosize="{
                minRows: 3,
              }"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="更新内容：" prop="updateContent">
            <el-input
                type="textarea"
                v-model="ruleForm.updateContent"
                placeholder="请输入产品更新内容"
                :autosize="{
                minRows: 2,
              }"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="原始著作权单位：" prop="originalCopyrightUnit">
            <el-input
              v-model="ruleForm.originalCopyrightUnit"
              placeholder="请输入原始著作权单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="发行单位：" prop="originalIssuingUnit">
            <el-input
              v-model="ruleForm.originalIssuingUnit"
              placeholder="请输入发行单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="发行版本：" prop="productVersion">
            <el-input
              v-model="ruleForm.productVersion"
              placeholder="请输入发行版本"
            ></el-input>
          </el-form-item>
          <el-form-item label="适用于：" prop="environmentList">
            <el-input
              v-model="ruleForm.environmentList"
              placeholder="请输入适用范围，例如：windows"
            ></el-input>
          </el-form-item>
          <el-form-item label="面向客群：" prop="faceCustomerGroup">
            <el-input
              v-model="ruleForm.faceCustomerGroup"
              placeholder="请输入面向客群"
            ></el-input>
          </el-form-item>
          <el-form-item label="合作方式：" prop="cooperationWay">
            <el-input
              v-model="ruleForm.cooperationWay"
              placeholder="请输入合作方式，例如：签约授权"
            ></el-input>
          </el-form-item>
          <el-form-item label="合作周期：" prop="cooperationPeriod">
            <el-input
              v-model="ruleForm.cooperationPeriod"
              placeholder="请输入合作周期"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用方式：" prop="usage">
            <el-input
              v-model="ruleForm.usage"
              placeholder="请输入使用方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="二次开发：" prop="secondaryDevelopment">
            <el-input
              v-model="ruleForm.secondaryDevelopment"
              placeholder="请输入二次开发"
            ></el-input>
          </el-form-item>

          <el-form-item label="软件可读版本：" prop="prodVersion">
            <el-input
                v-model="ruleForm.prodVersion"
                placeholder="请输入软件可读版本，如1.2，如果是对应软件版的文档，输入对应软件版本，可以进行关联"
            ></el-input>
          </el-form-item>

          <el-form-item label="类型（软件/文档）：" prop="prodType">
            <!-- 选择软件或者文档 -->
            <el-select v-model="ruleForm.prodType" placeholder="请选择" >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>

          </el-form-item>

          <el-form-item label="模块名：" prop="moduleName">
            <!-- 选择软件或者文档 -->
<!--            <el-input-->
<!--                v-model="ruleForm.moduleName"-->
<!--                placeholder="请输入软件模块名，如人事、财务模块等"-->
<!--            ></el-input>-->
            <el-select v-model="ruleForm.moduleName" placeholder="请选择" >
              <el-option
                  v-for="item in moduleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>

          </el-form-item>


          <el-form-item label="禁用申请和下载：" prop="showSign">
            <el-checkbox
              v-model="ruleForm.showSign"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
          </el-form-item>
          <div class="btn-grid">
            <el-button v-if="isPublish" @click="submitForm">修改</el-button>
            <el-button v-else type="primary" @click="submitForm"
              >保存发布</el-button
            >
          </div>
        </el-form>
      </el-card>
      <relevant-information
        @submit="submitForm"
        :hash="this.chainDto.chainHash"
        ref="relevant"
      />

      <el-tabs>
        <el-tab-pane label="PC端">
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">商品详情</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="detailEditor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.productDetails"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="detailEditorCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isProductDetailsPublish"
                @click="richTextSubmit(detailEditor, ruleForm.productDetails)"
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="richTextSubmit(detailEditor, ruleForm.productDetails)"
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">用户案例</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="exampleEditor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.userCase"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="exampleEditorCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isUserCasePublish"
                @click="richTextSubmit(exampleEditor, ruleForm.userCase)"
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="richTextSubmit(exampleEditor, ruleForm.userCase)"
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">服务支持</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="serviceEditor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.platformSupportScope"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="serviceEditorCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isPlatformSupportScopePublish"
                @click="
                  richTextSubmit(serviceEditor, ruleForm.platformSupportScope)
                "
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="
                  richTextSubmit(serviceEditor, ruleForm.platformSupportScope)
                "
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">在线演示</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="onlineEditor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.demoInfo"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onlineEditorCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isDemoInfoPublish"
                @click="richTextSubmit(onlineEditor, ruleForm.demoInfo)"
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="richTextSubmit(onlineEditor, ruleForm.demoInfo)"
              >
                保存发布
              </el-button>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="移动端">
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">商品详情</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="detailEditorMobile"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.productDetailsMobile"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="detailEditorMobileCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isProductDetailsPublish"
                @click="
                  richTextSubmit(
                    detailEditorMobile,
                    ruleForm.productDetailsMobile,
                  )
                "
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="
                  richTextSubmit(
                    detailEditorMobile,
                    ruleForm.productDetailsMobile,
                  )
                "
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">用户案例</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="exampleEditorMobile"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.userCaseMobile"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="exampleEditorMobileCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isUserCasePublish"
                @click="
                  richTextSubmit(exampleEditorMobile, ruleForm.userCaseMobile)
                "
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="
                  richTextSubmit(exampleEditorMobile, ruleForm.userCaseMobile)
                "
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">服务支持</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="serviceEditorMobile"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.platformSupportScopeMobile"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="serviceEditorMobileCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isPlatformSupportScopePublish"
                @click="
                  richTextSubmit(
                    serviceEditorMobile,
                    ruleForm.platformSupportScopeMobile,
                  )
                "
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="
                  richTextSubmit(
                    serviceEditorMobile,
                    ruleForm.platformSupportScopeMobile,
                  )
                "
              >
                保存发布
              </el-button>
            </div>
          </el-card>
          <el-card
            class="mar-btm-20"
            shadow="never"
            :body-style="cardBodyStyle"
          >
            <div slot="header">在线演示</div>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="onlineEditorMobile"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px; overflow-y: hidden; z-index: 9999"
                v-model="ruleForm.demoInfoMobile"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onlineEditorMobileCreated"
              />
            </div>
            <div class="btn-grid">
              <el-button
                v-if="isDemoInfoPublish"
                @click="
                  richTextSubmit(onlineEditorMobile, ruleForm.demoInfoMobile)
                "
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="
                  richTextSubmit(onlineEditorMobile, ruleForm.demoInfoMobile)
                "
              >
                保存发布
              </el-button>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>

      <div class="btn-grid" style="margin-right: 20px">
        <el-button @click="$router.go(-1)"> 返回 </el-button>
        <el-button
          type="primary"
          :disabled="!isPublish"
          @click="toProductDetail"
        >
          查看发布页
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import RelevantInformation from "@/pages/AccountCenter/MyProduct/components/RelevantInformation";
import {
  Button,
  Card,
  Form,
  FormItem,
  Input,
  Loading,
  Message,
  Upload,
  Checkbox,
  Tabs,
  TabPane,
} from "element-ui";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
import {
  productInfo,
  productRelease,
  uploadProductAccessory,
  uploadLogoAndVideo, moduleInfo,
} from "@/api/accountCenter";

export default {
  components: {
    RelevantInformation,
    [Card.name]: Card,
    [Input.name]: Input,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Editor,
    Toolbar,
    [Checkbox.name]: Checkbox,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      cardBodyStyle: {
        borderRadius: "8px",
      },
      id: undefined,
      load: undefined,
      logoList: [],
      videoList: [],
      ruleForm: {
        video: "",
        logo: "",
        productName: "",
        productIntroduction: "",
        updateContent: "",
        originalCopyrightUnit: "",
        originalIssuingUnit: "",
        productVersion: "",
        environmentList: "",
        faceCustomerGroup: "",
        cooperationWay: "",
        cooperationPeriod: "",
        usage: "",
        secondaryDevelopment: "",
        prodVersion: "",
        prodType: "",
        moduleName: "",
        demoInfo: "<p><br></p>",
        userCase: "<p><br></p>",
        platformSupportScope: "<p><br></p>",
        productDetails: "<p><br></p>",
        demoInfoMobile: "<p><br></p>",
        userCaseMobile: "<p><br></p>",
        platformSupportScopeMobile: "<p><br></p>",
        productDetailsMobile: "<p><br></p>",
        showSign: 0,
      },
      rules: {
        video: [{ required: true, message: "请上传首页主视频" }],
        logo: [{ required: true, message: "请上传logo" }],
        productName: [
          { required: true, message: "请输入产品名称", trigger: "change" },
        ],
        productIntroduction: [
          { required: true, message: "请输入slogan", trigger: "change" },
        ],
        updateContent: [
          { required: true, message: "请输入产品更新内容", trigger: "change" },
        ],
        originalCopyrightUnit: [
          {
            required: true,
            message: "请输入原始著作权单位",
            trigger: "change",
          },
        ],
        originalIssuingUnit: [
          { required: true, message: "请输入发行单位", trigger: "change" },
        ],
        productVersion: [
          { required: true, message: "请输入发行版本", trigger: "change" },
        ],
        environmentList: [
          { required: true, message: "请输入适用范围", trigger: "change" },
        ],
        faceCustomerGroup: [
          { required: true, message: "请输入面向客群", trigger: "change" },
        ],
        cooperationWay: [
          { required: true, message: "请输入合作方式", trigger: "change" },
        ],
        cooperationPeriod: [
          { required: true, message: "请输入合作周期", trigger: "change" },
        ],
        usage: [
          { required: true, message: "请输入适用方式", trigger: "change" },
        ],
        secondaryDevelopment: [
          { required: true, message: "请输入二次开发", trigger: "change" },
        ],
        prodVersion: [
          { required: true, message: "请输入软件可读版本", trigger: "change" },
        ],
        ProdType: [
          { required: true, message: "请选择产品类型文档/软件", trigger: "change" },
        ],
        moduleName: [
          { required: true, message: "请输入模块名", trigger: "change" },
        ],
      },
      options: [
        {
          value: '源代码',
          label: '源代码'
        },
        {
          value: '安装包',
          label: '安装包'
        },
        {
        value: '技术文档',
        label: '技术文档'
      },
        {
          value: '白皮书',
          label: '白皮书'
        }
      ],
      moduleOptions: [],
      detailEditor: null,
      exampleEditor: null,
      onlineEditor: null,
      serviceEditor: null,
      detailEditorMobile: null,
      exampleEditorMobile: null,
      onlineEditorMobile: null,
      serviceEditorMobile: null,
      isDemoInfoPublish: false,
      isUserCasePublish: false,
      isPlatformSupportScopePublish: false,
      isProductDetailsPublish: false,

      isDemoInfoPublishMobile: false,
      isUserCasePublishMobile: false,
      isPlatformSupportScopePublishMobile: false,
      isProductDetailsPublishMobile: false,
      isPublish: false,
      toolbarConfig: {
        excludeKeys: "fullScreen",
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const formData = new FormData();
              formData.append("file", file);
              const load = Loading.service({
                text: "上传中...",
              });
              try {
                const res = await uploadProductAccessory(formData);
                load.close();
                Message.success("上传成功");
                insertFn(res.data.pathUrl, "", "");
              } catch (e) {
                Message.warning("上传失败");
                load.close();
              }
            },
          },
          uploadVideo: {
            allowedFileTypes: ["video/mp4", "video/webm", "video/ogg"],
            maxFileSize: 1000 * 1024 * 1024, // 5M
            timeout: 600 * 1000, // 15 秒
            async customUpload(file, insertFn) {
              const formData = new FormData();
              formData.append("file", file);
              const load = Loading.service({
                text: "上传中...",
              });
              try {
                const res = await uploadProductAccessory(formData);
                load.close();
                Message.success("上传成功");
                insertFn(res.data.pathUrl);
              } catch (e) {
                Message.warning("上传失败");
                load.close();
              }
            },
          },
        },
      },
      chainDto: {
        chainName: "",
        createTime: "",
        downloadLink: "",
        chainHash: "",
      },
      mode: "default", // or 'simple'
    };
  },
  mounted() {
    if (this.$route.params.id !== "create") {
      this.id = this.$route.params.id;
      this.getProductInfo();
    }
    this.getModuleInfo();
  },
  methods: {
    richTextSubmit(editor, html) {
      if (!this.isPublish) {
        Message({
          message: "请先提交基础信息",
          type: "warning",
        });
      } else if (html === "<p><br></p>") {
        Message({
          type: "warning",
          message: "富文本编辑器不能为空",
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.submitForm();
          } else {
            Message({
              type: "warning",
              message: "请补充上方基础信息",
            });
          }
        });
      }
    },
    submitForm(authInfo) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (authInfo) {
            this.chainDto = { ...authInfo };
          }
          const formData = this.getParams();
          this.load = Loading.service({
            text: "提交中",
          });
          productRelease({ ...formData, chainDto: this.chainDto })
            .then((res) => {
              this.load.close();
              Message({
                message: "提交成功",
                type: "success",
              });
              this.id = res.data.id;
              this.isPublish = true;
              this.judgeIsPublish(res.data);
            })
            .catch(() => {
              Message({
                message: "提交失败",
                type: "error",
              });
              this.load.close();
            });
        } else {
          Message({
            type: "warning",
            message: "请填写基础信息",
          });
          console.log("error submit!!");
          return false;
        }
      });
    },
    
    getModuleInfo() {
      moduleInfo({}).then((res) => {
        console.log(res.data)
        this.moduleOptions = res.data.map(item => {
          return {
            value: item.keyValue,
            label: item.keyValue
          }
        })
      });
    },

    getProductInfo() {
      this.load = Loading.service({
        text: "加载中",
      });


      productInfo({ productId: this.id })
        .then((res) => {
          this.isPublish = true;
          Object.assign(this.ruleForm, res.data);
          this.ruleForm.cooperationPeriod =
            res.data.releaseProductSimpleParam.cooperationPeriod;
          this.ruleForm.cooperationWay =
            res.data.releaseProductSimpleParam.cooperationWay;
          this.ruleForm.secondaryDevelopment =
            res.data.releaseProductSimpleParam.secondaryDevelopment;
          this.ruleForm.prodVersion = res.data.prodVersion;
          this.ruleForm.prodType = res.data.prodType;
          this.ruleForm.moduleName = res.data.moduleName;
          this.ruleForm.usage = res.data.releaseProductSimpleParam.usage;
          this.ruleForm.logo = res.data.productLogo;
          this.ruleForm.video = res.data.productVideo;
          this.ruleForm.showSign = res.data.showSign;
          if (res.data.productLogo) {
            this.logoList = [
              {
                url: res.data.productLogo,
                name: "logo",
              },
            ];
          }
          if (res.data.productVideo) {
            this.videoList = [
              {
                url: res.data.productVideo,
                name: "首页视频",
              },
            ];
          }
          Object.assign(this.chainDto, res.data);
          this.chainDto.createTime = res.data.chainTime;
          this.$refs.relevant.initInfo({
            chainHash: res.data.chainHash,
            chainName: res.data.chainName,
            createTime: res.data.chainTime,
            downloadLink: res.data.downloadLink,
          });
          this.judgeIsPublish(res.data);
          this.load.close();
        })
        .catch(() => {
          this.load.close();
        });
    },

    getParams() {
      const form = this.ruleForm;
      return {
        id: this.id,
        demoInfo: form.demoInfo,
        environmentList: form.environmentList,
        faceCustomerGroup: form.faceCustomerGroup,
        originalCopyrightUnit: form.originalCopyrightUnit,
        originalIssuingUnit: form.originalIssuingUnit,
        platformSupportScope: form.platformSupportScope,
        productDetails: form.productDetails,
        productIntroduction: form.productIntroduction,
        updateContent: form.updateContent,
        productName: form.productName,
        prodVersion: form.prodVersion,
        prodType: form.prodType,
        moduleName: form.moduleName,
        productVersion: form.productVersion,
        releaseProductSimpleParam: {
          cooperationPeriod: form.cooperationPeriod,
          cooperationWay: form.cooperationWay,
          secondaryDevelopment: form.secondaryDevelopment,
          usage: form.usage,
        },
        userCase: form.userCase,
        productVideo: form.video,
        productLogo: form.logo,
        showSign: form.showSign,
        demoInfoMobile: form.demoInfoMobile,
        userCaseMobile: form.userCaseMobile,
        platformSupportScopeMobile: form.platformSupportScopeMobile,
        productDetailsMobile: form.productDetailsMobile,
      };
    },

    judgeIsPublish(richText) {
      if (richText.userCase !== "<p><br></p>") {
        this.isUserCasePublish = true;
      }
      if (richText.platformSupportScope !== "<p><br></p>") {
        this.isPlatformSupportScopePublish = true;
      }
      if (richText.productDetails !== "<p><br></p>") {
        this.isProductDetailsPublish = true;
      }
      if (richText.demoInfo !== "<p><br></p>") {
        this.isDemoInfoPublish = true;
      }

      if (richText.userCaseMobile !== "<p><br></p>") {
        this.isUserCasePublishMobile = true;
      }
      if (richText.platformSupportScopeMobile !== "<p><br></p>") {
        this.isPlatformSupportScopePublishMobile = true;
      }
      if (richText.productDetailsMobile !== "<p><br></p>") {
        this.isProductDetailsPublishMobile = true;
      }
      if (richText.demoInfoMobile !== "<p><br></p>") {
        this.isDemoInfoPublishMobile = true;
      }
    },

    toProductDetail() {
      this.$router.push({
        name: "detail",
        params: {
          id: this.id,
        },
      });
    },
    handleVideoRemove() {
      this.ruleForm.video = "";
      this.$refs.ruleForm.validateField("video");
    },
    handleLogoRemove() {
      this.ruleForm.logo = "";
      this.$refs.ruleForm.validateField("logo");
    },
    async customizeLogoUpload(params) {
      const res = await this.uploadLogoVideo(params.file);
      if (res) {
        this.ruleForm.logo = res;
      } else {
        this.$refs.logo.uploadFiles.splice(0, 1);
      }
      this.$refs.ruleForm.validateField("logo");
    },
    async customizeVideoUpload(params) {
      const res = await this.uploadLogoVideo(params.file);
      if (res) {
        this.ruleForm.video = res;
      } else {
        this.$refs.video.uploadFiles.splice(0, 1);
      }
      this.$refs.ruleForm.validateField("video");
    },
    handleVideoPreview() {
      window.open(this.ruleForm.video, "_blank");
    },
    async uploadLogoVideo(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.load = Loading.service({
        text: "上传中...",
      });
      try {
        const res = await uploadLogoAndVideo(formData);
        this.load.close();
        this.$message.success("上传成功");
        return res.data.pathUrl;
      } catch (e) {
        Message.warning("上传失败");
        this.load.close();
        return false;
      }
    },
    handleExceed() {
      Message.warning("只需要上传一个文件");
    },
    exampleEditorCreated(editor) {
      this.exampleEditor = Object.seal(editor);
    },
    detailEditorCreated(editor) {
      this.detailEditor = Object.seal(editor);
    },
    onlineEditorCreated(editor) {
      this.onlineEditor = Object.seal(editor);
    },
    serviceEditorCreated(editor) {
      this.serviceEditor = Object.seal(editor);
    },
    exampleEditorMobileCreated(editor) {
      this.exampleEditorMobile = Object.seal(editor);
    },
    detailEditorMobileCreated(editor) {
      this.detailEditorMobile = Object.seal(editor);
    },
    onlineEditorMobileCreated(editor) {
      this.onlineEditorMobile = Object.seal(editor);
    },
    serviceEditorMobileCreated(editor) {
      this.serviceEditorMobile = Object.seal(editor);
    },
  },
};
</script>

<style lang="scss" scoped>
.interval-16 {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}

.btn-grid {
  margin-top: 22px;
  display: flex;
  gap: 32px;
  justify-content: flex-end;

  /deep/ .el-button {
    width: 200px;
  }
}

.mar-btm-20 {
  margin-bottom: 20px;
}
</style>
