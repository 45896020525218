<template>
  <div>
    <el-card
      shadow="never"
      :body-style="cardBodyStyle"
      id="card"
      element-loading-text="拼命加载中"
    >
      <div slot="header">
        <span class="color-b mr-4">产品相关资料发布</span>会被上链确权
      </div>
      <el-form
        label-position="right"
        label-width="280px"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :model="ruleForm"
      >
        <el-form-item
          label="源码资料库（源码、操作手册压缩包）："
          prop="example"
        >
          <el-upload
            action="#"
            :before-upload="handleUpload"
            :file-list="fileList"
            :on-remove="handleRemove"
          >
            <el-button> 上传资料 </el-button>
            <span slot="tip"> 选择压缩包上传 </span>
          </el-upload>
        </el-form-item>
        <div v-if="ruleForm.isUpload">
          <div>确权信息</div>

          <el-form-item label="状态：">
            <span class="color-b">
              {{ ruleForm.createTime + " " + ruleForm.chainName + " 确权完成" }}
            </span>
          </el-form-item>
          <el-form-item label="hash值：">
            <span class="color-b">
              {{ ruleForm.chainHash }}
            </span>
          </el-form-item>
          <el-form-item label="下载地址：">
            <a class="color-b" :href="ruleForm.downloadLink" target="_blank">
              {{ ruleForm.downloadLink }}
            </a>
          </el-form-item>
        </div>
        <div class="btn-grid">
          <el-button @click="confirmRight">上链确权</el-button>
          <el-button :type="hash ? 'default' : 'primary'" @click="savePublish">
            {{ hash ? "修改" : "保存发布" }}
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { Button, Card, Form, FormItem, Loading, Upload } from "element-ui";
import {authRightWithHash} from "@/api/accountCenter";
import ipfsHttpClient from 'ipfs-http-client'

export default {
  components: {
    [Card.name]: Card,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Upload.name]: Upload,
  },
  props: {
    hash: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardBodyStyle: {
        borderRadius: "8px",
      },
      ruleForm: {
        isUpload: false,
        createTime: "",
        chainHash: null,
        downloadLink: "",
        chainName: "",
      },
      rules: {
        hash: [
          {
            required: true,
            message: "请上传文件",
          },
        ],
      },
      load: undefined,
      file: null,
      isSelectFile: false,
      fileList: [],
    };
  },
  mounted() {},
  methods: {
    handleUpload(file) {
      console.log(111);
      this.file = file;
      this.fileList = [
        {
          name: file.name,
          status: "ready",
        },
      ];
      this.isSelectFile = false;
      Promise.resolve().then(() => {
        this.isSelectFile = true;
      });
      return false;
    },
    handleRemove() {
      if (this.isSelectFile) {
        console.log("remove");
        this.file = null;
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(valid);
        } else {
          console.log("submit error!");
          return false;
        }
      });
    },
    savePublish() {
      if (this.ruleForm.chainHash) {
        this.$emit("submit", {
          createTime: this.ruleForm.createTime,
          chainHash: this.ruleForm.chainHash,
          downloadLink: this.ruleForm.downloadLink,
          chainName: this.ruleForm.chainName,
        });
      } else {
        this.$message.warning("请先进行文件确权");
      }
    },
    initInfo(auth) {
      Object.assign(this.ruleForm, auth);
      this.ruleForm.isUpload = auth.chainHash;
    },
    confirmRight() {
      if (this.file) {
        this.load = Loading.service({
          target: document.getElementById("card"),
          text: "上传中...",
        });
        const formData = new FormData();
        formData.append("file", this.file);
        console.log(this.file.name);

        //http ipfs上传
        this.saveToIpfs(this.file).then((hash) => {
          console.log(hash);
          // hash = eval('(' + hash.replaceAll(".", "") + ')');
          const fileHash = new FormData();
          fileHash.append("fileHash", hash);
          authRightWithHash(fileHash).then((res) => {
                clearInterval(this.timer);
                // res = eval('(' + res.replaceAll(".", "") + ')')
                this.ruleForm.isUpload = true;
                Object.assign(this.ruleForm, res.data);
                this.load.close();
              }).catch(() => {
            this.load.close();
          });
        }).catch(() => {
          this.load.close();
        });

        //java ipfs上传
        /*ipfsUpload(formData).then((hash) => {
          hash = eval('(' + hash.replaceAll(".", "") + ')');
          const fileHash = new FormData();
          fileHash.append("fileHash", hash.data.chainHash);
          authRightWithHash(fileHash).then((res) => {
                clearInterval(this.timer);
                // res = eval('(' + res.replaceAll(".", "") + ')')
                this.ruleForm.isUpload = true;
                Object.assign(this.ruleForm, res.data);
                this.load.close();
              }).catch(() => {
            this.load.close();
          });
        }).catch(() => {
          this.load.close();
        });*/
      } else {
        this.$message.warning("请选择确权文件");
      }
    },

    //http ipfs上传
    async saveToIpfs(file) {
      const ipfs = ipfsHttpClient("/ip4/61.172.179.4/tcp/5001");
      return new Promise(function (resolve,reject) {
        console.log("upload")
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = async () => {
            const fileBuffer = Buffer.from(fileReader.result);
            try {
              const {cid} = await ipfs.add(fileBuffer, {
                progress: (pong) => {
                  console.log(`receive : ${pong}`)
                }
              });
              console.log('File uploaded to IPFS. IPFS Hash:', cid.toString());
              resolve(cid.toString());
            } catch (error) {
              console.error('Error uploading to IPFS:', error);
              reject(error)
            }
          };

          fileReader.readAsArrayBuffer(file);
        } else {
          console.warn('No file selected for upload.');
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-grid {
  display: flex;
  gap: 32px;
  justify-content: flex-end;

  /deep/ .el-button {
    width: 200px;
  }
}

.color-b {
  color: #2299f5;
}

.mr-4 {
  margin-right: 4px;
}
</style>
